// Dependencies
import React from "react";
import { Link } from "gatsby";
// Components
import Image from "./Image";

// const PLACEHOLDER = 'https://images.ctfassets.net/cjn6vzfhi5uf/5yijHGQ7FOweZFel0id11N/dd4fb2744bda0208c93a65a0fb96746e/MSCTYxMEMU_RADIO_small.png';

const ArtistList = ({ title, artists, viewArtist }) => {

	return (
		<section className="listen-section">
			<div className="p1 innerx2">
				<h1>{title}</h1>
			</div>

			<div className="listen-artist-list innerx2">
				{artists.map((artist, index) => {
					if (!artist.track) {
						return null
					}
					return (
						<Link to={`/artist/${artist.slug}`} key={`${artist.id}${index}`}>
							<div className={`portrait ${artist.artwork ? '' : 'border'}`}>
								<div className="img">
									{artist.artwork && (
										artist.artwork.imageFront ? (
											<Image image={artist.artwork.imageFront.square} />
										) : artist.artwork.imageBack ? (
											<Image image={artist.artwork.imageBack.square} />
										) : <img src="" className="debug" alt="missing artist artwork" />
									)}
								</div>
							</div>
							<div className="p2 name">
								<strong>{artist.name}</strong><br />{viewArtist}
							</div>
						</Link>
					)
				})}
			</div>
		</section>
	)
}

export default ArtistList