// Dependencies
import React from "react";
import { graphql, Link } from "gatsby";
import PropTypes from "prop-types";
// Hooks
// import useMouse from "../hooks/useMouse";
import useMicrocopy from "../hooks/useMicrocopy";
// Components
import Artwork from "../components/Artwork";
import ArtistList from "../components/ArtistList";
import Layout from "../components/Layout";
import { PlayTrackButton } from "../components/MusicPlayer"
// import Section from "../components/Section";
// import SectionFeatures from "../components/SectionFeatures";
// import RichText from "../components/RichText";
// import PageSections from "../components/PageSections";

const PLACEHOLDER = 'https://images.ctfassets.net/cjn6vzfhi5uf/5yijHGQ7FOweZFel0id11N/dd4fb2744bda0208c93a65a0fb96746e/MSCTYxMEMU_RADIO_small.png';

const TrackLink = ({ track, artwork, playlist }) => {
  return (
    <div className="listen__item has-border">
			<div className="listen__cover artwork--square">
				<Link to={`/track/${track.slug}`}>
					{artwork ? (
						<Artwork {...artwork} />
					) : (
						<img src={PLACEHOLDER} alt="placeholder mscty" />
					)}
				</Link>
			</div>
			<PlayTrackButton {...track} playlist={playlist} />
		</div>
  )
}

const PlaylistLink = ({ slug, artwork, title, tracks }) => {
	return (
		<Link to={`/listen/${slug}`} className="listen__item">
			<div className="listen__cover artwork--square">
				{artwork ? (
					<Artwork {...artwork} />
				) : (
					<img src={PLACEHOLDER} alt="placeholder mscty" />
				)}
			</div>
			<div className="info">
				<p>
					<strong>{title}</strong> <br />
					{tracks && `View Tracks [${tracks.length}]`}
				</p>
			</div>
		</Link>
	)
}

const Playlist = (props) => {
	const { tracks } = props
	return (
		tracks.map((track, trackIndex) => {
			const artwork = track.artwork || (track.projectLink && track.projectLink.thumbnail) || (track.projectLink && track.projectLink.cover);
			return (
				<TrackLink track={track} playlist={props} artwork={artwork} key={`listen-to-track-${track.contentful_id}-${trackIndex}`} />
			)
		})
	)
}

const ListenPage = ({ data, pageContext: { locale } }) => {

  const { sections } = data.contentfulListenPage;
	const artists = data.allContentfulTrackArtist.nodes;
	const { viewArtist, allArtists } = useMicrocopy(locale)

  return (
    <Layout
      title={`Listen`}
      headerTitles={null}
      description={null}
      shareImage={null}
			locale={locale}
      colours={{
        primary: '#FFFFFF',
        secondary: '#0B0B0A'
      }}
      isPage={false}
    >
      <div className="listen-page">
        {sections && sections.map((section, index) => {
          return (
            <section className="innerx2" key={`section-${index}`}>
              <div className="listen__title innerx2 p1" key={`listen-section-${index}`}>
                <h1>{section.title}</h1>
              </div>
              <div className="listen__gallery guttersx2">
                <div className="listen__gallery__wrapper">
                  {section.__typename === 'ContentfulPlaylist' ? (
										<Playlist {...section} locale={locale} />
                  ) : (
                    section.playlists.map((list, listIndex) => {
											return list.tracks && section.playlists.length > 1 ? (
												<PlaylistLink {...list} locale={locale} key={`playlist-${listIndex}`} />
											) : list.tracks && (
												<Playlist tracks={list.tracks} locale={locale} key={`playlist-${listIndex}`} />
											)
										})
                  )}
                </div>
              </div>
            </section>
          )
        })}

				<ArtistList title={allArtists} artists={artists} viewArtist={viewArtist} />
      </div>
    </Layout>
  )
}

ListenPage.propTypes = {
  data: PropTypes.object.isRequired
}

export default ListenPage

export const ListenPagePageQuery = graphql`
  query ListenPageQuery($slug: String!, $locale: String!) {
    contentfulListenPage(
			slug: { eq: $slug }, 
			node_locale: { eq: $locale }
		) {
      title
			sections {
				... on ContentfulListenPageCollection {
					__typename
					title
					playlists {
						title
						slug
						artwork {
							...ContentfulArtworkFragment
						}
						tracks {
							...ContentfulTrackFragment
						}
					}
				}
				... on ContentfulPlaylist {
					__typename
					title
					artwork {
						...ContentfulArtworkFragment
					}
					tracks {
						...ContentfulTrackFragment
					}
				}
			}
    }
		## All artists
		allContentfulTrackArtist(
			filter: {slug: {ne: null}, node_locale: {eq: $locale}}
			sort: {fields: name, order: ASC}
		) {
			nodes {
				name
				slug
				track {
					slug
				}
				artwork {
					...ContentfulArtworkFragment
				}
			}
		}
  }
`
